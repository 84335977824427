import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { get } from "lodash";
import Layout from "../components/layout";
import SEO from "../components/seo";
import DoubleArrow from "../images/svg/angle-double-right-light.svg";
import Gallery from "@browniebroke/gatsby-image-gallery";
import { shuffle } from "../utils/helpers";

const BlogPostTemplate = ({ data, pageContext }) => {
  console.group('data');
  console.log(data);
  console.groupEnd();
  console.group('pageContext');
  console.log(pageContext);
  console.groupEnd();
  let galleryData = get(data, 'wpPost.posts.gallery');
  galleryData = galleryData ? shuffle(galleryData) : [];
  const galleryImages = galleryData.length > 0 ? galleryData.map((node) => {
    let image = node.localFile.childImageSharp;
    image = {
      ...image,
      caption: node.altText,
    };
    return image;
  }) : null;
  // console.log(data);
  return (
    <Layout baseRoute="blog-post">
      <SEO title={data.wpPost.title} description={data.wpPost.excerpt} />
      <GatsbyImage
        className="featured-image"
        layout="constrained"
        image={getImage(data.wpPost.featuredImage.node.localFile)}
        alt={data.wpPost.featuredImage.node.altText}
      />
      <div className="contained">
        <div className="breadcrumb">
          <Link to="/">Home</Link> <DoubleArrow className="icon-sm" />{" "}
          <Link to="/blog">Blog</Link>
        </div>

        <h1>{data.wpPost.title}</h1>
        <div
          className="post-content"
          dangerouslySetInnerHTML={{ __html: data.wpPost.content }}
        />
        {galleryImages && (
          <div className="gallery">
            <Gallery images={galleryImages} />
          </div>
        )}

        <div className="post-mini-footer-wrapper">
          <span className="categories">
            {data.wpPost.categories && "in "}
            {data.wpPost.categories &&
              data.wpPost.categories.nodes.map((node, index) => {
                return (
                  <span key={index}>
                    {index > 0 && <span className="spaced">/</span>}
                    <Link to={`${node.uri}`} className="category-link">
                      {node.name}
                    </Link>
                  </span>
                );
              })}
          </span>
          <span className="date">{data.wpPost.date}</span>
        </div>
        <div className="post-prev-next-wrapper">
          <div className="nav-link">
            {pageContext.prev && (
              <Link
                to={pageContext.prev.uri}
                title={pageContext.prev.title}
                alt={pageContext.prev.title}
              >
                « Prev
              </Link>
            )}
          </div>
          <div className="nav-link">
            {pageContext.next && (
              <Link
                to={pageContext.next.uri}
                title={pageContext.next.title}
                alt={pageContext.next.title}
              >
                Next »
              </Link>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );};
export default BlogPostTemplate;

export const query = graphql`
  query ($id: String!) {
    wpPost(id: { eq: $id }) {
      title
      content
      excerpt
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          caption
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP, JPG]
                transformOptions: { fit: COVER, cropFocus: ATTENTION }
                backgroundColor: "transparent"
                layout: FULL_WIDTH
                aspectRatio: 2.96
              )
            }
          }
        }
      }
      author {
        node {
          name
        }
      }
      categories {
        nodes {
          count
          name
          uri
        }
      }
      parent {
        ... on WpPage {
          id
          title
          uri
          slug
        }
      }
      posts {
        gallery {
          altText
          description
          localFile {
            childImageSharp {
              thumb: gatsbyImageData(
                width: 270
                height: 270
                placeholder: DOMINANT_COLOR
              )
              full: gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
